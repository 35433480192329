
#NactiZasilkyBlockNeodeslane {
    margin-bottom: 2rem;

}
header{
    display: inline;
    font-size: 0.5rem;
    height: fit-content;
    /*text-align: left;*/
}

.subheader{
    display: inline;
    font-size: 1.8rem;
    /*text-align: left;*/
}

.tblZasilky {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    /*width: inherit;*/
    margin-bottom: 2rem;
}

.tblZasilky table
{
}

.tblZasilky th
{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 0.2rem solid rgb(128, 128, 128);
}

.tblZasilky td
{
    border: 0.2rem solid rgb(128, 128, 128);
    padding-left: 0.5rem;
    padding-right: 0.5rem;

}

.tblZasilky tr:nth-child(even){background-color: #f3f3f3;}

.tblZasilky tr:nth-child(odd){background-color: #AAAAAAFF;}

.tblZasilky tr:hover {background-color: #ddd;}


.tblZasilky th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: var(--bg-color);
    color: var(--main-text-color);
}

.tblZasilky .errorNote {
    background: var(--main-warning);
}

.tblZasilky .okNote {
    background: var(--main-warning);
}

hr {
    background-color: var(--bg-color);
    color: var(--main-text-color);
    height: .3rem;
}

input[type=text], select {
    width: 20rem;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type=submit] {
    width: 10rem;
    background-color: #275096;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #1c59bf;
}

/*div {*/
/*    border-radius: 5px;*/
/*    background-color: #f2f2f2;*/
/*    padding: 20px;*/
/*}*/
