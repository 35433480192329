@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,700,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --gradient: linear-gradient(135deg, #72edf2 10%, #5151e5 100%);
  --bg-color: #73b7d9;
  --bg-color--card: #222831;
  /*--main-text-color: #ffffff;*/
  --main-text-color:#133453;
  --main-warning: #BA5D7E;
  --main-orange: #ffb81d;
  --button: #aec97e;
  --main-yellow: #ebdf5e;
  --main-border-radius: 25px;
  --secondary-border-radius: 15px;
}

body {
  width: 100%;
  /*height: 100vh;*/
  /*height: 100%;*/

  font-family: 'Montserrat', sans-serif;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url(/src/images/armorik-plage-1-light.webp);
  color: var(--main-text-color);
}


.App {
  text-align: center;
  /*margin-bottom: 1rem;*/
  padding-top: 1rem;
  padding-bottom: 1rem;

}

.App .container{
  display: flex;
  max-width: 90%;
  /*height: 50vh;*/
  margin-bottom: 5px;
}

#Zapati {
  position:absolute;
  bottom:0;
  /*display:flex;*/
  /*flexDirection: column;*/
  /*min-width: fit-content(100%);*/
}

#error {
  list-style: none;
  color: crimson;
  font-size: 20px;
  font-weight: bold;
}

.col-centered {
  float: none;
  margin-right: auto;
  margin-left: auto;
}

.monitoring {
  display: none;
}

.box {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin: 0 auto;
  border-radius: var(--main-border-radius);
  -webkit-box-shadow: 0 0 24px 7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 24px 7px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0.6));
  color: var(--main-text-color);
  width: 85%;
  max-width: 675px;
  min-height: 300px;
}

.input {
  border: none;
  text-align: center;
  border-radius: var(--main-border-radius);
  margin: 10px auto;
  font-weight: 700;
  line-height: initial;
  width: 100%;
  height: 38px;
}
.button {
  border: none;
  border-radius: var(--main-border-radius);
  padding: 0.65rem;
  background: var(--bg-color);
  color: var(--main-text-color);
  font-weight: 700;
  box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  line-height: initial;
  width: 100%;
  height: 38px;
}
.button:hover {
  color: var(--main-text-color);
  /*-webkit-transform: scale(1.05);*/
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}
.button_odesli_podminene {
  background: var(--main-orange);
}

.button_odesli {
  background: var(--button);
}

.loader_block {
  margin-top: 2rem;
  margin-bottom: 2rem;

}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid var(--bg-color);
  border-bottom: 16px solid var(--bg-color);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  /*0% { -webkit-transform: rotate(0deg); }*/
  /*100% { -webkit-transform: rotate(360deg); }*/
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

